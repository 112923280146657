<template>
  <div>
    <v-row class="match-height">
      <v-col cols="12">
        <v-card>
          <v-card-title>Add Multiple Post</v-card-title>
          <v-card-text>
            <v-form ref="form" lazy-validation class="multi-col-validation">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    :rules="[rules.required]"
                    v-model="item.title"
                    label="Name"
                    outlined
                    dense
                    placeholder="Name"
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6">
                  <v-autocomplete
                    :rules="[rules.required]"
                    :items="cats"
                    item-text="name"
                    item-value="id"
                    label="Category"
                    v-model="item.categories_id"
                    chips
                    clearable
                    small-chips
                  ></v-autocomplete>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6">
                  <v-textarea
                    name="input-7-1"
                    label="Description"
                    value=""
                    outlined
                    dense
                    v-model="item.description"
                  ></v-textarea>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="item.hastag1"
                    label="Hastag 1"
                    outlined
                    dense
                    placeholder="Hastag 1"
                    hide-details
                  ></v-text-field>
                  <v-text-field
                    class="mt-4"
                    v-model="item.hastag2"
                    label="Hastag 2"
                    outlined
                    dense
                    placeholder="Hastag 2"
                    hide-details
                  ></v-text-field>
                  <v-text-field
                    class="mt-4"
                    v-model="item.hastag3"
                    label="Hastag 3"
                    outlined
                    dense
                    placeholder="Hastag 3"
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6">
                  <v-select
                    item-text="text"
                    item-value="value"
                    :items="relatedTos"
                    label="Related To"
                    v-model="item.related_to"
                    dense
                    outlined
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6" v-if="item.related_to == 1">
                  <v-autocomplete
                    v-model="item.related_to_user"
                    item-text="name"
                    item-value="item_id"
                    :items="users"
                    label="Related To User"
                    chips
                    clearable
                    small-chips
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="6">
                  <v-file-input multiple accept="image/*" @change="setImg" v-model="item.new_image" label="Image" outlined dense></v-file-input>
                </v-col>

                <v-col cols="12" md="6">
                  <v-checkbox class="mt-0" v-model="item.custom_name" value="1" false-value="0">
                    <template v-slot:label>
                      <div>Need to add Custom Name ?</div>
                    </template>
                  </v-checkbox>
                  <v-checkbox class="mt-0" v-model="item.custom_image" value="1" false-value="0">
                    <template v-slot:label>
                      <div>Need to add Custom Image ?</div>
                    </template>
                  </v-checkbox>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6">
                  <v-select
                    item-text="text"
                    item-value="value"
                    :items="statuses"
                    label="Status"
                    v-model="item.status"
                    dense
                    outlined
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="item.comment"
                    label="Comment"
                    outlined
                    dense
                    placeholder="Comment"
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6">
                  <v-select
                    item-text="text"
                    item-value="value"
                    :items="langs"
                    label="Language"
                    v-model="item.color"
                    dense
                    outlined
                  ></v-select>
                </v-col>

                <v-col cols="12">
                  <v-btn @click="submit" :loading="process" color="primary"> Submit </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiMagnify, mdiPencilOutline } from '@mdi/js'
import { serialize } from 'object-to-formdata'
export default {
  name: 'PostAddMulti',
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiPencilOutline,
      },
    }
  },
  data() {
    return {
      item: {
        title: null,
        categories_id: null,
        description: null,
        hastag1: null,
        hastag2: null,
        hastag3: null,
        related_to: null,
        related_to_user: null,
        comment: null,
        image: null,
        custom_name: 0,
        custom_image: 0,
        tranding: 0,
        status: 0,
        color: 5,
      },
      fileType: null,
      users: [],
      cats: [],
      statuses: [
        {
          text: 'Inactive',
          value: 0,
        },
        {
          text: 'Active',
          value: '1',
        },
        {
          text: 'Deleted',
          value: '2',
        },
        {
          text: 'Pending',
          value: '3',
        },
        {
          text: 'Rejected',
          value: '4',
        },
      ],
      relatedTos: [
        {
          text: 'All Users',
          value: '0',
        },
        {
          text: 'Specific Users',
          value: '1',
        },
      ],
      langs: [
        {
          text: 'ગુજરાતી',
          value: '5',
        },
        {
          text: 'हिंदी',
          value: '6',
        },
        {
          text: 'English',
          value: '7',
        },
      ],
      rules: {
        required: value => !!value || 'Required.',
      },
      process: false,
    }
  },
  computed: {
    init() {
      return this.$store.getters['Init/init']
    },
  },
  created() {
    this.load(1)
  },
  methods: {
    load(page) {
      var url = 'posts?page=' + page
      this.$api.get(url).then(response => {
        this.users = response.data.users
        this.cats = response.data.post_categories
      })
    },
    setImg() {
      if (this.item.new_image == null) {
        this.item.image = null
      } else {
        if (this.item.new_image.type.includes('video')) {
          this.fileType = 'video'
          let video = document.getElementById('video-preview')
          let reader = new FileReader()

          reader.readAsDataURL(this.item.new_image)
          reader.addEventListener('load', function () {
            video.src = reader.result
          })
        } else if (this.item.new_image.type.includes('image')) {
          this.fileType = 'image'
          this.item.image = URL.createObjectURL(this.item.new_image)
        } else {
          this.$toast.error('Please upload image or video only.')
          this.item.image = null
          this.fileType = null
          this.item.new_image = null
        }
      }
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.process = true
        const options = {
          indices: false,
        }
        const formData = serialize(this.item, options)
        this.$api
          .post('posts', formData, {
            'Content-Type': 'multipart/form-data',
          })
          .then(response => {
            this.$toast.info(response.message)
            this.$router.push({ name: 'PostList' })
            this.process = true
          }).catch((error)=> {
            this.process = true
            this.$toast.error(error.response.data.message[0])
          })
      }
    },
  },
}
</script>
